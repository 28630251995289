<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      color="background"
      right
      floating
      :width="$vuetify.breakpoint.mdAndUp ? '30%' : '100%'"
      transition="fade-transition"
    >
      <v-list-item class="px-0">
        <v-list-item-title class="mx-4">
          <v-img
            style="cursor: pointer"
            @click="$router.push({ name: 'Home' })"
            contain
            width="111"
            :style="
              $vuetify.theme.dark ? '' : 'filter: brightness(1) invert(1);'
            "
            :src="require('@/assets/logos/woozLabs_white.svg')"
          >
          </v-img>
        </v-list-item-title>

        <!-- <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img> -->
        <v-list-item-avatar class="ma-5">
          <v-btn @click="$emit('toggleDrawer')" icon color="secondary">
            <feather type="x-circle" />
          </v-btn>
        </v-list-item-avatar>
      </v-list-item>

      <v-list flat>
        <v-list-group
          v-for="item in items"
          :key="item.title"
          v-model="item.active"
          :append-icon="item.items ? 'mdi-chevron-down' : ''"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content
              @click="
                item.to
                  ? routerPush(item.to)
                  : item.link
                  ? routerPush(item.link, true)
                  : ''
              "
            >
              <v-list-item-title
                class="font-weight-bold"
                :class="$vuetify.breakpoint.smAndUp ? 'display-1' : 'headline'"
                v-text="$t(`system.navigationDrawer.menu.${item.title}`)"
              >
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <div v-if="item.items">
            <v-list-item v-for="child in item.items" :key="child.title">
              <v-list-item-content
                @click="child.to ? routerPush(child.to) : ''"
                class="pa-4"
              >
                <span>
                  <v-list-item-title
                    class="title font-weight-bold"
                    v-text="$t(`system.navigationDrawer.child.${child.title}`)"
                  />
                </span>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-group>
      </v-list>
      <div class="ma-5" style="bottom: 0; right: 0; position: absolute">
        <!-- DARK -->
        <v-btn class="mx-2" @click="toggleTheme()" color="secondary" icon>
          <feather color="secondary" v-if="$vuetify.theme.dark" type="sun" />
          <feather color="secondary" v-else type="moon" />
        </v-btn>

        <v-btn @click="toggleLanguage()" icon color="secondary">
          <v-img
            v-if="$i18n.locale == 'en'"
            contain
            height="28"
            :style="
              $vuetify.theme.dark ? 'filter: brightness(0) invert(1);' : ''
            "
            :src="require('@/assets/icons/korea.svg')"
          >
          </v-img>
          <feather v-else type="globe" />
        </v-btn>

        <!-- LOGIN ICON -->
        <v-btn
          v-if="!$store.getters['auth/user/GET_USER']"
          class="mx-2"
          color="onSurface"
          @click="$router.push({ name: 'Login' })"
          icon
          :ripple="false"
        >
          <feather type="user" />
        </v-btn>

        <more-menu :left="true" :top="true" :origin="'right bottom'" v-else>
          <template v-slot:moreMenuTrigger="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              width="40"
              height="40"
              class="background mx-2"
              icon
              depressed
              :ripple="false"
            >
              <v-avatar size="36" color="surface">
                <v-img :src="$store.getters['auth/user/GET_USER'].photoURL" />
              </v-avatar>
            </v-btn>
          </template>
        </more-menu>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import MoreMenu from "@/components/Page/Menu/MoreMenu.vue";
export default {
  props: ["drawer"],
  components: { MoreMenu },

  computed: {
    language: {
      get() {
        return this.$store.getters["page/settings/CURRENT_LANGUAGE"];
      },
      set(val) {
        this.$store.dispatch("page/settings/SET_LANGUAGE", val);
        this.$i18n.locale = val;
      },
    },
  },
  mounted() {},
  methods: {
    toggleTheme() {
      if (this.$vuetify.theme.dark) {
        this.$analyticsLog(this.$analytics, "theme_toggle", {
          from: "dark",
          to: "light",
        });
      } else {
        this.$analyticsLog(this.$analytics, "theme_toggle", {
          from: "light",
          to: "dark",
        });
      }
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    toggleLanguage() {
      if (this.$i18n.locale === "en") {
        this.$analyticsLog(this.$analytics, "language_toggle", {
          from: "en",
          to: "ko",
        });
        this.language = "ko";
      } else {
        this.$analyticsLog(this.$analytics, "language_toggle", {
          from: "ko",
          to: "en",
        });
        this.language = "en";
      }
    },
    routerPush(routerName, external, link) {
      if (external) {
        location.href = routerName;
      }
      if (routerName === "") {
        alert("준비중입니다.");
        return;
      }
      if (this.$router.currentRoute.name !== routerName) {
        this.$router
          .push({ name: routerName })
          .then(() => window.scrollTo(0, 0));
      }
    },
  },
  data() {
    return {
      items: [
        {
          title: "resume",
          link: "https://woozlabs.notion.site/Kim-Kwan-Woo-0d4f6371566b4f92b15f9c18cb58b4da",
        },
        {
          active: false,
          items: [{ title: "tradingAI", to: "Trading" }, { title: "empty" }],
          title: "techservice",
        },
        {
          title: "archive",
          to: "Archive",
        },
      ],
    };
  },
};
</script>

<style></style>
