<template>
  <v-menu
    v-if="$store.getters['auth/user/GET_USER']"
    v-model="menu"
    :close-on-content-click="false"
    offset-y
    :top="top"
    left
    transition="scale-transition"
    :origin="origin ? origin : 'right top'"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot v-bind="{ on, attrs }" name="moreMenuTrigger"> </slot>
    </template>

    <v-list outlined nav dense class="rounded-lg">
      <v-list-item
        :ripple="false"
        @click="$router.push({ name: 'MyPage' })"
        two-line
      >
        <v-list-item-avatar>
          <v-img
            v-if="$store.getters['auth/user/GET_USER']"
            :src="$store.getters['auth/user/GET_USER'].photoURL"
          />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-weight-bold body-1">
            {{ $store.getters["auth/user/GET_USER"].displayName }}
          </v-list-item-title>
          <v-list-item-subtitle class="font-weight-regular body-2">
            내 프로필 보기
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item-group class="my-2">
        <v-list-item
          active-class="primary--text"
          :ripple="false"
          v-for="(item, i) in items"
          :key="i"
          @click="$router.push({ name: item.to })"
        >
          <v-avatar size="32" color="transparent">
            <v-icon color="onSurface" v-text="item.icon"></v-icon>
          </v-avatar>

          <v-list-item-content class="ml-2">
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    top: Boolean,
    origin: String,
  },
  data() {
    return {
      menu: false,

      selectedItem: 1,
      items: [
        { text: "설정 및 개인정보", icon: "mdi-cog", to: "MyPage" },
        { text: "도움말 및 지원", icon: "mdi-help-circle", to: "" },
        {
          text: "디스플레이 및 접근성 ",
          icon: "mdi-moon-waning-crescent",
          to: "",
        },
        { text: "로그아웃 ", icon: "mdi-logout", to: "Logout" },
      ],
    };
  },
};
</script>

<style></style>
